@import url(https://stackpath.bootstrapcdn.com/bootstrap/5.0.0-alpha2/css/bootstrap.min.css);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


.link_c-link__vIaWg {
    color: red;
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
}
.link_c-link_zone__SGFKu {
    display: flex;
    color: green;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    box-shadow: 0 0 4px rgba(48, 74, 88, 0.432);
    background-color: #ffffffaa;
    max-width: 320px;
}

.c-video {
    width: 100%;
}

.o-header {
    min-height: 3rem;
    display: grid;
}

