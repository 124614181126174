.c-link {
    color: red;
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
}
.c-link_zone {
    display: flex;
    color: green;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    box-shadow: 0 0 4px rgba(48, 74, 88, 0.432);
    background-color: #ffffffaa;
    max-width: 320px;
}
